import React, { ReactElement } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

interface Props {
	data: {
		markdownRemark: {
			html: string
			excerpt: string
			frontmatter: {
				title: string
				description: string
				date: string
			}
		}
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
	pageContext: unknown
	location: Location
}

const ProjectPostTemplate = ({
	data /*, pageContext*/,
}: Props): ReactElement => {
	const post = data.markdownRemark
	// const { previous, next } = pageContext

	return (
		<Layout>
			<SEO
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
			/>
			<article>
				<header>
					<h1
						style={{
							marginTop: rhythm(1),
							marginBottom: 0,
						}}
					>
						{post.frontmatter.title}
					</h1>
					<p
						style={{
							...scale(-1 / 5),
							display: `block`,
							marginBottom: rhythm(1),
						}}
					>
						{post.frontmatter.date}
					</p>
				</header>
				<section dangerouslySetInnerHTML={{ __html: post.html }} />
			</article>

			<nav>
				<ul
					style={{
						display: `flex`,
						flexWrap: `wrap`,
						justifyContent: `space-between`,
						listStyle: `none`,
						marginLeft: 0,
						padding: 0,
					}}
				>
					{/* <li>
						{previous && (
							<Link to={previous.fields.slug} rel="prev">
								← {previous.frontmatter.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={next.fields.slug} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</li> */}
				</ul>
			</nav>
		</Layout>
	)
}

export default ProjectPostTemplate

export const pageQuery = graphql`
	query ProjectPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			html
			frontmatter {
				title
				date(formatString: "YYYY-MM-DD")
				description
			}
		}
	}
`
